@import '@/components/variables.scss';

.button {

  background-color: var(--w_primaryColor);
  color: var(--w_primaryText);
  transition: all 0.5s ease;
  font-weight: 400;
  font-size: 14px;
  padding-left: spacer(lg);
  padding-right: spacer(lg);
  display: flex;
  height: $x-field-height;
  line-height: calc($x-field-height - 2px);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: var(--w_buttonBorderRadius);
  border: 1px solid transparent;
  width: 100%;
  position: relative;
  text-decoration: none;
  text-transform: var(--w_buttonTextStyle, uppercase);
  cursor: pointer;
  overflow: hidden;

  &:hover {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(255,255,255,0.2);
    }
  }
  
  span {
    flex: 0 0 auto;
    font-size: 14px;
    + i {
      margin-left: spacer(xs)
    }
    + svg {
      margin-left: spacer(xs)
    }
  }
  
  i {
    flex: 0 0 auto;
    height: 16px;
    + span {
      margin-left: spacer(xs);
      font-size: 14px;
    }
    svg {
      width: 16px;
      height: 16px;
      margin-bottom: -2px;
    }

  }
  
  > input[type=file] {
    display: block;
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
  }
  
  &:disabled {
    background-color: #DDDDDD;
    color: #999999;
    border-color: #DDDDDD;
  }

  &-rows {
    flex-direction: column;
  }
  
  &-primary {
    background-color: var(--w_primaryColor);
    color: var(--w_primaryText);
  }
  
  &-secondary {
    background-color: var(--w_secondaryColor);
    color: var(--w_secondaryText);
  }
  
  &-green {
    background-color: var(--w_colorGreen);
    color: #FFFFFF;
  }
  
  &-icon {
    color: var(--w_primaryText);
    width: 44px;
    padding: 0 0;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  &-icon-primary {
    background-color: var(--w_primaryColor);
    color: var(--w_primaryText);
  }

  &-icon-green {
    background-color: var(--w_colorGreen);
    color: white;
  }

  &-icon-big-white {
    background-color: transparent;
    color: white;
    padding: 0 0 0 0;
    width: 44px;
    height: 44px;
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &-badge {
    display: block;
    position: absolute;
    top: -2px;
    right: -2px;
    height: 16px;
    min-width: 16px;
    padding: 0 4px;
    line-height: 16px;
    background-color: var(--w_colorRed);
    color: white;
    font-size: .675rem;
    border-radius: 999999px;
    text-align: center;
  }
  
  &-float {
    display: block;
    position: fixed;
    z-index: 10;
    &-bottom-center {
      bottom: spacer(xl);
      float: left;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &-line {
    border: 1px solid var(--w_primaryColor);
    background-color: transparent;
    color: var(--w_primaryColor);
    &:hover {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(var(--w_primaryColor),0.2);
      }
    }
  }

  &-line-reverse {
    border: 1px solid var(--w_primaryText);
    background-color: transparent;
    color: var(--w_primaryText);
    &:hover {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(var(--w_primaryText),0.2);
      }
    }
  }

  &-icon-line {
    border: 1px solid var(--w_primaryColor);
    background-color: transparent;
    color: var(--w_primaryColor);
    width: $x-field-height;
    padding: 0 0;
    &:hover {
      color: var(--w_primaryText);
      background-color: var(--w_primaryColor);
    }
    svg {
      width: calc($x-field-height / 2.5);
      height: calc($x-field-height / 2.5);
    }
  }

  &-icon-sm {
    width: $x-field-height;
    padding: 0 0;
    svg {
      width: calc($x-field-height / 2.5);
      height: calc($x-field-height / 2.5);
    }
  }

  &-icon-only {
    background-color: transparent;
    border-color: transparent;
    color: black;
  }

  &-inline {
    display: inline-block;
    vertical-align: top;
    width: auto;
  }

  &-header {
    height: 32px;
  }

  &-payment {
    svg {
      width: 44px;
      height: 24px;
    }
  }

  &-google_pay, &-apple_pay, &-affirm, &-klarna, &-afterpay_clearpay {
    background-color: black !important;
    color: white !important;
    text-transform: none !important;
    svg {
      width: 44px;
      height: 44px;
      margin-left: 4px;
    }
    img {
      height: 36px;
    }
  }
  &-apple_pay {
    svg {
      width: 44px;
      height: 36px;
    }
  }
  &-affirm {
    svg {
      width: 44px;
      height: 24px;
    }
  }
  &-klarna {
    img {
      height: 20px;
      margin-left: 6px;
    }
  }

}

.processing {
  color: #FFF;
  background-color: rgba(0,0,0,0.5);
  transition: all 0.5s ease;
  overflow: hidden;
  span, img, svg {
    opacity: 0 !important;
    transition: all 0.5s ease;
  }

  &-ring {

    opacity: 1 !important;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    left: 50%;
    top: 50%;
    float: left;
    transform: translate(-50%,-50%);
    transition: all 0.5s ease;

    > div {

      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 24px;
      height: 24px;
      border: 2px solid white;
      border-radius: 50%;
      margin: 4px;
      animation: processingRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: white transparent transparent transparent;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }
      &:nth-child(2) {
        animation-delay: -0.3s;
      }
      &:nth-child(3) {
        animation-delay: -0.15s;
      }
  
    }

  }

  &-primary {
    &.processing-ring {
      > div {
        border: 2px solid var(--w_primaryColor);
        border-color: var(--w_primaryColor) transparent transparent transparent;
      }
    }
  }

  &-inline {
    position: relative;
    flex: 0 0 auto;
    left: auto;
    top: auto;
    float: none;
    transform: none;
  }

}

@keyframes processingRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Termhub cookie banner customization

.th-ccb {
  z-index: 2000 !important;
}
.th-button {
  background-color: var(--w_primaryColor) !important;
  color: var(--w_primaryText) !important;
  border-radius: var(--w_buttonBorderRadius) !important;
  border-width: 0 !important;
  transition: all 0.3s ease !important;
  &:hover {
    transform: none !important;
    box-shadow: inset 0  0 10000px rgba(0,0,0,0.2);
  }
  &.text {
    background-color: transparent !important;
    color: var(--w_primaryColor) !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    &:hover {
      box-shadow: none !important;
    }
  }
}
.th-ccb {
  border-radius: var(--w__tileBorderRadius) !important;
}
.th-ccb__right {
  margin-top: spacer(base) !important;
}
.ccb__reopen--bottom-left {
  position: fixed !important;
  background: transparent !important;
  bottom: 0 !important;
  left: 50% !important;
  display: block!important;
  margin-left: -20px !important;
  width: 40px !important;
}
.th-ccb-switch>input:checked+span {
  background-color: var(--w_primaryColor) !important;
}